import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg'

import { injectIcons } from './injectIcons'
import { tokens } from '../'
import * as values from '../icons/mdi'

export const minifyTheme = css => css
  .replace(/\n\s?|\s(?=[\w!#\-{])/g, '')
  .replace(/\s{2,}/g, ' ')

export const vuetify = createVuetify({
  icons: {
    iconfont: 'mdi',
    aliases: {
      ...aliases,
      ...values
    },
    sets: {
      mdi
    }
  },
  theme: {
    defaultTheme: 'acvTheme',
    themes: {
      acvTheme: {
        colors: tokens.colors
      }
    },
    options: {
      customProperties: true,
      minifyTheme
    }
  },
  defaults: {
    VAlert: {
      variant: 'tonal'
    },
    VSelect: {
      variant: 'underlined'
    },
    VProgressLinear: {
      color: 'primary'
    },
    VProgressCircular: {
      color: 'primary'
    }
  },
  components,
  directives
})

export default {
  install (_app, options = {}) {
    vuetify.icons.aliases = {
      ...vuetify.icons.aliases,
      ...options.icons
    }
  }
}

export const addIcons = iconsToAdd => {
  injectIcons(vuetify, iconsToAdd)
}
