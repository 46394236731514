export default [
  {
    component: () => import(
      './MVSG.vue'
    ),
    name: 'stocking.mvsg',
    path: '/stocking/mvsg'
  },
  {
    component: () => import(
      './Autobuy.vue'
    ),
    name: 'stocking.autobuy',
    path: '/stocking/autobuy'
  }
]
