// @todo(vue3-migration): Once we are fully migrated to Vue3 and no longer needed,
// remove this file, the @vue/compat package and any references to it.
export default {
  ATTR_FALSE_VALUE: false, // https://v3-migration.vuejs.org/breaking-changes/attribute-coercion.html
  ATTR_ENUMERATED_COERCION: true,
  COMPILER_V_ON_NATIVE: true, // https://v3-migration.vuejs.org/breaking-changes/v-on-native-modifier-removed.html
  COMPILER_FILTERS: true,
  COMPILER_INLINE_TEMPLATE: true, // https://v3-migration.vuejs.org/breaking-changes/inline-template-attribute.html
  COMPILER_V_BIND_SYNC: true, // https://v3-migration.vuejs.org/breaking-changes/v-model.html
  COMPILER_V_IF_V_FOR_PRECEDENCE: true, // https://v3-migration.vuejs.org/breaking-changes/v-if-v-for.html
  COMPILER_V_BIND_OBJECT_ORDER: true, // https://v3-migration.vuejs.org/breaking-changes/v-bind.html
  COMPONENT_V_MODEL: false, // https://v3-migration.vuejs.org/breaking-changes/v-model.html
  COMPONENT_FUNCTIONAL: false, // https://v3-migration.vuejs.org/breaking-changes/functional-components.html
  COMPONENT_ASYNC: false, // https://v3-migration.vuejs.org/breaking-changes/async-components.html
  CUSTOM_DIR: true, // https://v3-migration.vuejs.org/breaking-changes/custom-directives.html - check
  CONFIG_KEY_CODES: true, // https://v3-migration.vuejs.org/breaking-changes/keycode-modifiers.html
  CONFIG_PRODUCTION_TIP: true, // https://v3-migration.vuejs.org/breaking-changes/global-api.html#config-productiontip-removed
  FILTERS: true, // https://v3-migration.vuejs.org/breaking-changes/filters.html
  GLOBAL_MOUNT: true, // https://v3-migration.vuejs.org/breaking-changes/global-api.html#mounting-app-instance
  GLOBAL_MOUNT_CONTAINER: true, // https://v3-migration.vuejs.org/breaking-changes/mount-changes.html
  GLOBAL_PROTOTYPE: true, // https://v3-migration.vuejs.org/breaking-changes/global-api.html#vue-prototype-replaced-by-config-globalproperties
  GLOBAL_EXTEND: true, // https://v3-migration.vuejs.org/breaking-changes/global-api.html#vue-extend-removed
  INSTANCE_ATTRS_CLASS_STYLE: false, // https://v3-migration.vuejs.org/breaking-changes/attrs-includes-class-style.html
  INSTANCE_CHILDREN: true, // https://v3-migration.vuejs.org/breaking-changes/children.html
  INSTANCE_EVENT_EMITTER: true, // https://v3-migration.vuejs.org/breaking-changes/events-api.html
  INSTANCE_EVENT_HOOKS: true, // https://v3-migration.vuejs.org/breaking-changes/vnode-lifecycle-events.html - check
  INSTANCE_LISTENERS: false, // https://v3-migration.vuejs.org/breaking-changes/listeners-removed.html
  OPTIONS_DATA_FN: true, // https://v3-migration.vuejs.org/breaking-changes/data-option.html
  OPTIONS_DATA_MERGE: false, // https://v3-migration.vuejs.org/breaking-changes/data-option.html
  PROPS_DEFAULT_THIS: true, // https://v3-migration.vuejs.org/breaking-changes/props-default-this.html - check
  TRANSITION_GROUP_ROOT: true, // https://v3-migration.vuejs.org/breaking-changes/transition-group.html
  RENDER_FUNCTION: 'suppress-warning', // https://v3-migration.vuejs.org/breaking-changes/render-function-api.html
  V_ON_KEYCODE_MODIFIER: true, // https://v3-migration.vuejs.org/breaking-changes/keycode-modifiers.html
  WATCH_ARRAY: true // https://v3-migration.vuejs.org/breaking-changes/watch.html - check
}
