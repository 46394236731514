export default [
  {
    component: () => import(
      /* webpackChunkName: "inventory.rules.main" */
      './index.vue'
    ),
    name: 'inventory.rules.main',
    path: '/inventory/rules',
    children: [
      {
        component: () => import(
          /* webpackChunkName: "inventory.rules" */
          './List/index.vue'
        ),
        name: 'inventory.rules',
        path: ''
      },
      {
        component: () => import(
          /* webpackChunkName: "inventory.rules.add" */
          './Manager/index.vue'
        ),
        name: 'inventory.rules.add',
        path: 'add'
      },
      {
        component: () => import(
          /* webpackChunkName: "inventory.rule" */
          './Manager/index.vue'
        ),
        name: 'inventory.rule',
        path: ':id'
      }
    ]
  }
]
