import { VBtn } from 'vuetify/components/VBtn'
import { VDivider } from 'vuetify/components/VDivider'
import { VContainer } from 'vuetify/components/VGrid'

import NetworkCheck from '../components/NetworkCheck.vue'
import { useAuth } from '~/plugins/auth'
import { ProgrammaticDialog } from '~/ui/elements'
import Logo from '~/ui/logos/wordmark.vue'
const NetworkDialog = ProgrammaticDialog(NetworkCheck)

export default function ErrorLayout () {
  const auth = useAuth()

  return [
    h(VContainer, {
      class: ['justify-center', 'flex-column', 'fill-height']
    }, [
      h(Logo, {
        flat: true,
        color: 'primary',
        width: 80
      }),
      [
        h('span', {
          class: 'ma-3 text-error'
        }, auth.error.message),
        h('div', {
          class: 'd-flex justify-center'
        }, [
          h(VBtn, {
            color: 'primary',
            variant: 'flat',
            size: 'small',
            onClick: () => auth.error.action()
          }, auth.error.label),
          h(VDivider, {
            class: 'mx-3',
            vertical: true
          }),
          h('button', {
            class: 'caption',
            onClick: () => NetworkDialog.open()
          }, 'Still facing issues? Check your network')
        ])
      ]
    ])
  ]
}
