import { VContainer } from 'vuetify/components/VGrid'
import { VProgressCircular } from 'vuetify/components/VProgressCircular'

import { useAuth } from '~/plugins/auth'
import Logo from '~/ui/logos/wordmark.vue'

export default function LoadingLayout () {
  const auth = useAuth()

  return [
    h(VContainer, {
      class: ['justify-center', 'flex-column', 'fill-height']
    }, [
      h(Logo, {
        width: 120
      }),
      h(VProgressCircular, {
        class: 'ma-4',
        color: 'grey',
        indeterminate: true,
        size: 18,
        width: 2
      }),
      h('span', {
        class: `ma-4 caption text-center text-${auth.redirectTimedOut ? 'warning' : 'grey'}`,
        style: { maxWidth: '16rem', height: '3rem' }
      }, auth.loadingMessage)
    ])
  ]
}
