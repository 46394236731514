import accounting from './accounting'
import { encode as btoa, decode as atob } from './base64url'
import capitalize, { normalize } from './capitalize'
import currency from './currency'
import date from './date'
import drivetrain from './drivetrain'
import ellipses from './ellipses'
import number from './number'
import percent from './percent'
import phone from './phone'

export const filters = Object.freeze(markRaw({
  btoa,
  atob,
  accounting,
  capitalize,
  normalize,
  currency,
  date,
  ellipses,
  number,
  phone,
  percent,
  drivetrain
}))

export const filtersInjectionKey = Symbol('filters')

/**
 * Vue plugin to provide filters globally
 * @type {import('vue').Plugin}
 */
const plugin = {
  install (app) {
    app.config.globalProperties.$filters = filters
    app.provide(filtersInjectionKey, filters)
  }
}

/**
 * Injects the filters object into the current component instance
 * @returns {typeof filters}
 */
export function useFilters () {
  return inject(filtersInjectionKey)
}

export {
  btoa,
  atob,
  accounting,
  capitalize,
  normalize,
  currency,
  date,
  ellipses,
  number,
  phone,
  percent,
  drivetrain
}

export default plugin
