<script>

import DealerAutocomplete from './DealerAutocomplete.vue'
import MenuApp from './MenuApp.vue'
import MenuEnterprise from './MenuEnterprise.vue'
import MenuHelp from './MenuHelp/index.vue'
import MenuUser from './MenuUser.vue'
import Logo from '~/ui/logos/wordmark.vue'

export default {
  name: 'ToolbarApp',
  components: {
    DealerAutocomplete,
    Logo,
    MenuApp,
    MenuHelp,
    MenuUser,
    MenuEnterprise
  },
  props: {
    enterprise: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<template>
  <VAppBar
    :color="enterprise ? 'dark' : 'primary'"
    class="v-toolbar--app d-print-none"
    flat
    absolute
  >
    <template #title />
    <router-link
      :to="{ name: 'homepage' }"
      class="logo"
      title="ACV MAX"
    >
      <Logo
        white
        width="120"
      />
    </router-link>
    <MenuEnterprise
      v-if="enterprise"
      class="mx-4"
    />
    <MenuApp
      v-else
      class="mx-4"
    />
    <VSpacer />
    <MenuHelp />
    <DealerAutocomplete />
    <MenuUser />
  </VAppBar>
</template>

<style lang="scss">
@use '~/styles/mixins' as *;

.v-toolbar--app {
  @include z-index(app-bar, !important);

  .v-toolbar-title {
    display: none;
  }

  .v-toolbar__content {
    padding: 4px 16px;

    > .v-btn.v-btn--icon:last-child {
      margin-right: -12px;
    }
  }

  .v-toolbar__content > a {
    display: inherit;
  }

  .v-btn__content {
    letter-spacing: normal;
  }

  .v-toolbar__content a.logo {
    height: 100%;
  }
}
</style>
