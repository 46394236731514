export default `
  fragment dealerConfig on Dealer {
    hasACVWholesale: hasUpgrade(id: 38)
    hasACVGuarantee: hasUpgrade(id: 58)
    hasACVMAXRetail: hasUpgrade(id: 59)
    config {
      hasCrmOptedIn:hasFeature(product: "ims", feature: "CrmOptedIn")
      inTransit:hasFeature(product: "ims", feature: "inTransit")
      hasRequestACVInspection:hasFeature(product: "ims", feature: "RequestACVInspection")
      ims {
        features {
          newCarPricing
        }
      }
    }
  }
`
