import appraisals from './appraisals'
import dashboard from './dashboard'
import enterprise from './enterprise'
import inventory from './inventory'
import reports from './reports'
import rules from './rules'
import settings from './settings'
import stocking from './stocking'
import { auth } from '../plugins/auth'

const DASHBOARD_PATH = '/dashboard'

// Order matters here, the first route that matches will be used.
export default function withRoutes (router) {
  [
    appraisals,
    dashboard,
    enterprise,
    rules,
    inventory,
    reports,
    settings,
    stocking
  ].flat().map(route =>
    router.addRoute(route)
  )

  // Allow direct linking to a specific store or group from any other application.
  router.addRoute({
    path: '/for/:buid',
    redirect (to) {
      auth.switchDealer(to.params.buid, { targetPath: DASHBOARD_PATH })

      return DASHBOARD_PATH
    }
  })

  // Decide where to redirect the user after they log in
  router.addRoute({
    name: 'homepage',
    path: '/',
    meta: { layout: 'none' }, // avoid flash to blue header empty page
    component: () => import('./HomePage.vue')
  })

  // Global 404 handler, redirects back to the homepage if a bad link is used.
  router.addRoute({ path: '/:pathMatch(.*)*', redirect: DASHBOARD_PATH })

  return router
}
